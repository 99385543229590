<template>
    <b-modal ref="modal" hide-footer>
        <template v-slot:modal-title>
            {{ $t('gynecologie.active_other_season') }}
        </template>

        <template>
        	<SeasonInput v-model="season_selected"/>
	        <div class="text-center mt-3">
	            <b-button variant="primary" :disabled="processing" @click="confirm">
	                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
	                {{ $t('global.ajouter') }}
	            </b-button>
	        </div>
        </template>
    </b-modal>
</template>

<script type="text/javascript">
    import Gynecologie from "@/mixins/Gynecologie.js"

    export default {
        name: 'ModalActiveSeason',
        mixins: [Gynecologie],
        data () {
            return {
                processing: false,
                mares_ids: [],
                season_selected: null
            }
        },
        methods: {
			async openModal(mares_ids) {
                this.mares_ids = mares_ids
                this.$refs.modal.show()
            },
			async confirm() {
				this.processing = true;
				await this.activeMareSeason(this.mares_ids, this.season_selected.id)
                this.successToast()
                this.$refs.modal.hide()
                this.processing = false;
			},
        },
        components: {
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            SeasonInput : () => import('@/components/Inputs/SeasonInput')
        }
    }
</script>